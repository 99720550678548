import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import ProfilesPageBanner from './ProfilesPageBanner';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';

const VIEW_PROFILES_URL = '/profiles/';

function Profiles({id, img}) {
    const axiosPrivate = useAxiosPrivate();
    const [profiles, setProfiles] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSet, setPageSet] = useState(0);
    const [page, setPage] = useState(1);
    
      useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PROFILES_URL + id + '?page=' + page).then((response) => {
            if(isMounted){
                setProfiles(response.data);
                setTotalPages(response.data.totalPages);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [page]);
    
    let team = '';
    let ptag = '';
    if(id === 10){
        team = 'governors';
        ptag = 'Governors'
    }else if(id === 20){
         team = 'management';
        ptag = 'Management'
    }else if(id === 30){
        team = 'teachers';
        ptag = 'Teachers'
    }

    let pages = [];
    const pageSets = Math.ceil(totalPages/5);
  
    for(let i = pageSet; i<totalPages; i++){
        pages.push(i);
    }

    return (
        <>
            <ProfilesPageBanner tag={ptag} team={team} img={img}/>           
            <section className='pt-90 pb-120 gray-bg'>
                <div className="container">
                    <div className="row">
                        { profiles.map((value)=>{
                            return (
                                <div className="col-lg-3 col-sm-6" key={value.id}>
                                    <div className="singel-teachers mt-30 text-center">
                                        <div className="image">
                                            <img src={img + value.pictures} alt="Profile"/>
                                        </div>
                                        <div className="cont">
                                            <Link to={`profiles/view/${value.id}`}><h6>{value.name}</h6></Link>
                                            <span>{value.position}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>{/* row with profile cards*/}
                    <div className="col-lg-12">
                        <nav className="courses-pagination mt-50">
                            <ul className="pagination justify-content-center">
                            {pageSet!==1 &&
                                <li className="page-item">
                                <Link to="#previous" aria-label="Previous" onClick={(e)=>{setPageSet(pageSet-5)}}>
                                    <i className="fa fa-angle-left"></i>
                                </Link>
                                </li>                                                
                            }
                            {
                                pages.slice(0,5).map((pageNum, index) => {return <li className="page-item" key={index}><Link className="active" to={`#page=${pageNum}`} onClick={(e)=>setPage(pageNum)}>{pageNum}</Link></li>})
                            }
                            {pageSet<pageSets && 
                                <li className="page-item">
                                <Link to="#next" aria-label="Next" onClick={(e)=>{setPageSet(pageSet+5)}}>
                                    <i className="fa fa-angle-right"></i>
                                </Link>
                                </li>                                                    
                            }
                            </ul>
                        </nav>  { /*<!-- courses pagination --> */}
                    </div>         
                </div>
            </section>
        </>
    )
}

export default Profiles