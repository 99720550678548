import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate } from 'react-router-dom';
import GalleryPageBanner from './GalleryPageBanner';
import { useEffect, useState } from 'react';

const VIEW_GALLERIES_URL = '/galleries';

function Galleries({img}) {
  const axiosPrivate = useAxiosPrivate();
  const [galleries, setGalleries] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSet, setPageSet] = useState(1);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();  
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_GALLERIES_URL + '?page=' + page).then((response) => {
      if(isMounted){
        setGalleries(response.data.galleries);
        setTotalPages(response.data.totalPages);
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [page]);

  let pages = [];
  const pageSets = Math.ceil(totalPages/5);

  for(let i = pageSet; i<totalPages; i++){
      pages.push(i);
  }

  return (
    <>
      <GalleryPageBanner img={img}/>
      <section id="blog-page" className="pt-90 pb-120 gray-bg">
        <div className="container">
          <div className="row">
            { galleries.length>0 ?
              <>            
                {galleries.map((value)=>{
                  return (
                    <div key={value.id} className='col-lg-4 mt-30 col-md-6'>
                      <div className='singel-blog' onClick={() => navigate(`/gallery/view/${value.id}`)}>
                        <div className="blog-thum">
                          <img src={img + value.picture} alt="" />
                        </div>   
                        <div className="blog-cont">
                          <Link to={`/gallery/view/${value.id}`}><h4>{value.name}</h4></Link>
                          <ul>
                            <li><Link to='#date'><i className="fa fa-calendar"></i>{new Date(value.createdAt).toDateString()}</Link></li>
                          </ul>
                          <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: value.description}} />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="col-lg-12">
                    <nav className="courses-pagination mt-50">
                      <ul className="pagination justify-content-center">
                        {pageSet!==1 &&
                          <li className="page-item">
                            <Link to="#previous" aria-label="Previous" onClick={(e)=>{setPageSet(pageSet-5)}}>
                              <i className="fa fa-angle-left"></i>
                            </Link>
                          </li>                                                
                        }
                        {
                            pages.slice(0,5).map((pageNum, index) => {return <li className="page-item" key={index}><Link className="active" to={`#page=${pageNum}`} onClick={(e)=>setPage(pageNum)}>{pageNum}</Link></li>})
                        }
                        {pageSet<pageSets && 
                          <li className="page-item">
                            <Link to="#next" aria-label="Next" onClick={(e)=>{setPageSet(pageSet+5)}}>
                              <i className="fa fa-angle-right"></i>
                            </Link>
                          </li>                                                    
                        }
                      </ul>
                    </nav>  { /*<!-- courses pagination --> */}
                </div>
              </>              
            :
              <>
                  <div className='contact-from mt-30 mb-300 col-lg-12'>
                    <div className="section-title">
                      <h5>gallery</h5>
                      <h2>Photo Galleries</h2>
                    </div> { /* <!-- section title --> */ }
                    <div className='blog-details pt-45 mb-200'>
                      <p>There are no photos at the moment.<br /><br /></p> <h6><Link to='/'>Return to the Home Page</Link></h6>
                    </div>
                  </div>
              </>
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default Galleries