import RequireAuth from '../Components/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import ViewEvents from './Events/ViewEvents';
import ViewEvent from './Events/ViewEvent';
import AddEvent from './Events/AddEvent';

function Events({img}) {
  return (
    <Routes>
      <Route exact path='/' element={<ViewEvents img={img}/>} />
      <Route exact path='/view/:id' element={<ViewEvent img={img}/>} />
      <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
        <Route exact path='/add' element={<AddEvent img={img}/>} />
      </Route>
    </Routes>
  )
}

export default Events