import RightSideBar from '../../Components/RightSideBar';
import {Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';

//Editor
import EditorToolbar, { modules, formats } from "../../Components/EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../css/TextEditor.css";
import NewsPageBanner from './NewsPageBanner';

const VIEW_STORY_URL = '/news/view/';
const DELETE_STORY_URL = '/news/delete/';
const UPLOAD_FILE_URL = '/singleUpload';
const EDIT_STORY_URL = '/news/edit/';

function Story({img}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [newsStory, setNewsStory] = useState({});  
    const [title, setTitle] = useState('');
    const [detail, setDetail] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_STORY_URL + id).then((response)=>{
            if(isMounted){
                setNewsStory(response.data);  
                setTitle(response.data.title);
                setDetail(response.data.detail);            
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const deletePost = async(id) => {
        try {
            axiosPrivate.delete(DELETE_STORY_URL + id).then(()=>{
                alert('News Story Deleted');
                navigate('/news');
            }).catch(function(error){
                console.log(error.config);
            });               
        } catch (error) {    }
    };

    const onDetail = (value) => {
        setDetail(value);
    } 

    const updatePost = async(e) => {
        e.preventDefault();
        const updatedStory = {title, detail, userId: authDecode.user.id, id: newsStory.id};

        if(file){
            const formData = new FormData();
            const filename = Date.now() + file.name;
            
            formData.append("name", filename);         
            formData.append('file', file);

            updatedStory.picture = filename;

            try{              
                await axiosPrivate.post(UPLOAD_FILE_URL, formData);             
            }catch(err){
              console.log(err);
            }
        }
        
        try {
            axiosPrivate.put(EDIT_STORY_URL + newsStory.id, updatedStory).then(()=>{
                alert('News Story Edited');
                setUpdateMode(false);
                navigate(`/news/view/${newsStory.id}`);
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    return (
        <>
            <NewsPageBanner PT={newsStory?.title} img={img}/>
            <section className="pt-90 pb-120 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-details mt-30">
                                { file ? (
                                    <img src={URL.createObjectURL(file)} alt="" />
                                ): (
                                    newsStory?.picture && <img src={img + newsStory.picture} alt="" style={{height: '500px', objectFit: 'cover'}} />
                                )}
                                { updateMode ?
                                    <div className='main-form pt-45'> 
                                        <form className="p-2">
                                            <div className="form-group">
                                                <label htmlFor="fileInput"><h5>Change Image: <i className="fa fa-recycle" /></h5> </label>
                                                <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                            </div>
                                            <div className="form-group section-title">
                                                <br /><h5>Title</h5> 
                                                <input className="form-control p-2" required="required" type='text' value={title} onChange={(e)=>setTitle(e.target.value)} />
                                            </div>
                                            <div className="form-group section-title">
                                                <br /><h5>Story Details</h5>
                                                <EditorToolbar toolbarId={'t1'} />
                                                <ReactQuill theme="snow" rows="10" modules={modules('t1')} formats={formats}  value={detail} onChange={onDetail} />
                                            </div>
                                            <br />
                                            <button type="submit"className="main-btn" onClick={updatePost}>Update</button>
                                            &nbsp;&nbsp;&nbsp;  
                                            <button className="main-btn" onClick={(e)=>setUpdateMode(false)}>Cancel</button>
                                        </form>
                                    </div> 
                                : 
                                    <div className="cont">
                                        <h3 className="mb-3 text-secondary text-capitalize font-weight-bold">{newsStory?.title}</h3>
                                        <ul>
                                            <li><Link to="#date"><i className="fa fa-calendar"></i>{new Date(newsStory?.createdAt).toDateString()}</Link></li>
                                            { (authDecode && (authDecode?.user?.id === newsStory.userId || authDecode?.user?.role >= 4)) && 
                                                <>
                                                    <li><Link to="#edit"><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/></Link>Edit</li>
                                                    <li><Link to="#delete"><i className="fa fa-trash" aria-hidden="true" onClick={() => deletePost(newsStory.id)} /></Link>Delete</li>
                                                </>
                                            }
                                        </ul> 
                                        <p className="mt-3 p-3" dangerouslySetInnerHTML={{ __html: newsStory.detail}} style={{whiteSpace: "pre-line"}} />
                                    </div>
                                }
                            </div>
                        </div>
                        <RightSideBar />  
                    </div>
                </div>
            </section>
        </>
    )
}

export default Story