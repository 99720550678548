/* eslint-disable jsx-a11y/anchor-has-content */
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';
import {jwtDecode} from 'jwt-decode';
import EventsPageBanner from './EventsPageBanner';

const VIEW_EVENTS_URL = '/events';
const DELETE_EVENT_URL = '/events/delete/';

function ViewEvents({img}) {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  
    const [calEvents, setCalEvents] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSet, setPageSet] = useState(1);
    const [page, setPage] = useState(1);

    const navigate = useNavigate();
    
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_EVENTS_URL + '?page=' + page).then((response)=>{
        if(isMounted){
            setCalEvents(response.data.calEvents);
            setTotalPages(response.data.totalPages);
        }
      }).catch((error) => {
        //Handle Errors Coming out of this
    });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
    }, [page]);
  
  
    const deleteCalEvent = async(id) => {
      try {
        axiosPrivate.delete(DELETE_EVENT_URL + id).then(()=>{
          alert('Calendar Event Deleted');
          navigate('/events');
        });               
      } catch (error) {       
      }
    };

    let pages = [];
    const pageSets = Math.ceil(totalPages/5);

    for(let i = pageSet; i<=totalPages; i++){
        pages.push(i);
    }

    return (
        <>
            <EventsPageBanner img={img}/>
            <section id="blog-page" className="pt-90 pb-120 gray-bg">
                <div className='container'>
                    <div className="row">
                        { calEvents.length>0 ? 
                            <>
                                { calEvents.map((calEvent) => {
                                    return (
                                        <div key={calEvent.id} className="col-lg-4 mt-30 col-md-6">
                                            <div className="singel-blog">
                                                <div className="blog-thum">
                                                    <img src={img + calEvent.picture} alt="Event" />
                                                </div>
                                                <div className="blog-cont">
                                                    <h4><Link to={`/events/view/${calEvent.id}`}><h4>{calEvent.title}</h4></Link></h4>
                                                    <ul>
                                                        <li><Link to='#date'><i className="fa fa-calendar"></i> {new Date(calEvent.date).toDateString()}</Link></li>
                                                        <li><Link to='#location'><i className="fa fa-map-marker"></i> {calEvent.location}</Link></li>
                                                        {(authDecode && (authDecode?.user?.id === calEvent.UserId || authDecode?.user?.role >=4)) &&   
                                                            <li><Link to='#delete'><i className="fa fa-trash" aria-hidden="true" onClick={() => deleteCalEvent(calEvent.id)} /> Delete</Link></li>
                                                        }
                                                    </ul>
                                                    <p className='paraLimit2' dangerouslySetInnerHTML={{ __html: calEvent.description}} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="col-lg-12">
                                    <nav className="courses-pagination mt-50">
                                        <ul className="pagination justify-content-center">
                                            {pageSet!==1 &&
                                                <li className="page-item">
                                                    <Link to="#previous" aria-label="Previous" onClick={(e)=>{setPageSet(pageSet-5)}}>
                                                        <i className="fa fa-angle-left"></i>
                                                    </Link>
                                                </li>                                                
                                            }
                                            {
                                                pages.slice(0,5).map((pageNum, index) => {return <li className="page-item" key={index}><Link className="active" to={`#page=${pageNum}`} onClick={(e)=>setPage(pageNum)}>{pageNum}</Link></li>})
                                            }
                                            {pageSet<pageSets && 
                                                <li className="page-item">
                                                    <Link to="#next" aria-label="Next" onClick={(e)=>{setPageSet(pageSet+5)}}>
                                                        <i className="fa fa-angle-right"></i>
                                                    </Link>
                                                </li>                                                    
                                            }

                                        </ul>
                                    </nav>  { /*<!-- courses pagination --> */}
                                </div>

                            </>
                        :
                            <div className="col-lg-8">
                                <div className='contact-from mt-30 mb-300'>
                                <div className="section-title">
                                    <h5>events</h5>
                                    <h2>Upcoming Events</h2>
                                </div> { /* <!-- section title --> */ }
                                <div className='blog-details pt-45 mb-200'>
                                    <p>There are no scheduled events at the moment.<br /><br /></p> <h6><Link to='/'>Return to the Home Page</Link></h6>
                                </div>
                                </div>
                            </div>
                        }
                    </div> { /*<!-- row --> */}
                </div>
            </section>
        </>
    )
}

export default ViewEvents
