import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'

const VIEW_PROFILES_URL = '/profiles/home';

function Teachers({img}) {
  const [teachers, setTeachers] = useState([]);
  const [pageText, setPageText] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_PROFILES_URL).then((response) => {
          if(isMounted){
            setTeachers(response.data.profiles);
            setPageText(response.data.message);            
          }
      }).catch((error) => {
          console.log('Error', error.message);
      });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
  }, []);

  return (
    teachers.length>0 && 
    <section id="teachers-part" className="pt-60 pb-60">
        <div className="container">
          <div className="row">
            {pageText && 
              <div className="col-lg-5">
                      <div className="section-title mt-50">
                        <h5>{pageText.tag}</h5>
                        <h2>{pageText.title}</h2>
                      </div>
                      <div className="teachers-cont">
                        <p className="about-cont pt-20" dangerouslySetInnerHTML={{ __html: pageText.pageIntro}} style={{whiteSpace: "pre-line"}} />
                        <Link to="/vacancies" className="main-btn mt-55">Career with us</Link>
                      </div>
                    </div> 
                }
            <div className="col-lg-6 offset-lg-1">
              <div className="teachers mt-20">
                <div className="row">
                  { teachers.map((value) =>{
                    return(
                      <div key={value.id} className="col-sm-6">
                      <div className="singel-teachers mt-30 text-center">
                        <div className="image" style={{height: '270px'}}>
                          <img src={img + value.pictures} alt="Teachers"/>
                        </div>
                        <div className="cont">
                          <Link to={`profiles/view/${value.id}`}><h6>{value.name}</h6></Link>
                          <span>{value.position}</span>
                        </div>
                      </div> 
                    </div>
                    )
                  })}
                </div> 
              </div> 
            </div>
          </div> 
        </div> 
    </section>
  )
}

export default Teachers