/* eslint-disable jsx-a11y/anchor-has-content */
import {Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';

//Editor
import EditorToolbar, { modules, formats } from "../../Components/EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../css/TextEditor.css";
import ProfilesPageBanner from './ProfilesPageBanner';

const VIEW_PROFILE_URL = '/profiles/view/';
const DELETE_PROFILE_URL = '/profiles/delete/';
const UPLOAD_FILE_URL = '/singleUpload';
const EDIT_PROFILE_URL = '/profiles/edit/';

function Profile({img}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [profile, setProfile] = useState({});  
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [tag, setTag] = useState('');
    const [bio, setBio] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PROFILE_URL + id).then((response)=>{
            if(isMounted){
                setProfile(response.data);
                setName(response.data.name);
                setTag(response.data.tag);
                setPosition(response.data.position);
                setBio(response.data.bio);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const deleteProfile = async(id) => {
        try {
            axiosPrivate.delete(DELETE_PROFILE_URL + id).then(()=>{
                alert('Profile Deleted');
                navigate('/profiles');
            }).catch(function(error){
                console.log(error.config);
            });               
        } catch (error) {            
        }
    };

    const onbio = (value) => {
        setBio(value);
    } 

    const updateProfile = async(e) => {
        e.preventDefault();
        const updatedProfile = {name, tag, position, bio, userId: authDecode.user.id, id: profile.id};
        const profileId = profile.id;

        if(file){
            const formData = new FormData();
            const filename = Date.now() + file.name;            
            formData.append("name", filename);
            formData.append("file", file);
            updatedProfile.pictures = filename;
      
            try{
                await axiosPrivate.post(UPLOAD_FILE_URL, formData);
                alert('Image Posted');
            }catch(err){
              console.log(err);
            }
        }
        
        try {
            axiosPrivate.put(EDIT_PROFILE_URL + profileId, updatedProfile).then(()=>{
                alert('Profile Edited');
                setUpdateMode(false);
                navigate(`/profiles/view/${profileId}`);
            });   
        }catch (error) {
              console.log(error);  
        }
    }

    let team = '';
    let ptag = '';
    if(profile?.tag === 10){
        team = 'governors';
        ptag = 'Governors'
    }else if(profile?.tag === 20){
         team = 'management';
        ptag = 'Management'
    }else if(profile?.tag === 30){
        team = 'teachers';
        ptag = 'Teachers'
    }

    return (
        <>
            <ProfilesPageBanner tag={ptag} team={team} PT={name} img={img}/>
            <section id="teachers-singel" className="pt-70 pb-120 gray-bg">
                <div className="container">    
                    { updateMode ? 
                        <div className='main-form'>
                            <form className="p-2">
                                <div className="row">
                                    <div className="col-lg-4 col-md-8">
                                        <div className="teachers-left mt-50">
                                            <div className="hero">
                                                { file ? (
                                                    <img src={URL.createObjectURL(file)} alt={name} />
                                                ): (
                                                    profile?.pictures && <img src={img + profile?.pictures} alt={name} />
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="fileInput">
                                                    <br /><h5>Change Profile Image: <i className="fa fa-recycle"></i></h5>
                                                </label>
                                                <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                            </div>
                                            <div className="form-group section-title">
                                                <h5>Name</h5>
                                                <input type="text" value={name} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setName(e.target.value)} />
                                            </div>
                                            <div className="form-group section-title">
                                                <br /><h5>Group</h5>
                                                <select name="tag" value={tag} onChange={(e) => setTag(e.target.value)}>
                                                    {!tag ? <option defaultValue>Select Grouping</option> : <option>Select Grouping</option>}
                                                    { tag ===10 ? <option value="10" defaultValue>Governors</option> : <option value="10">Governors</option>}
                                                    { tag ===30 ? <option value="20" defaultValue>Management</option> : <option value="20">Management</option>}
                                                    { tag ===30 ? <option value="30" defaultValue>Teachers</option> : <option value="30">Teachers</option>}
                                                </select>
                                            </div>
                                            <div className="form-group section-title">
                                                <br /><h5>Position</h5>
                                                <input type="text" value={position} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setPosition(e.target.value)} />
                                            </div>
                                        </div> { /* <!-- teachers left --> */}
                                    </div>
                                    <div className="col-lg-8 mt-50">
                                        <div className='form-group section-title'>
                                            <h5>Bio:</h5>
                                            <EditorToolbar toolbarId={'t1'} />
                                            <ReactQuill theme="snow" rows="10" modules={modules('t1')} formats={formats} value={bio} onChange={onbio} />
                                        </div>
                                        <br />
                                        <button className="main-btn" type="submit" onClick={updateProfile}>Update</button>
                                        &nbsp;&nbsp;&nbsp;  
                                        <button className="main-btn" onClick={()=>setUpdateMode(false)}>Cancel</button>

                                    </div>
                                </div>
                            </form>
                        </div>
                    :                    
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-8">
                                <div className="teachers-left mt-50">
                                    <div className="hero">
                                        { file ? (
                                            <img src={URL.createObjectURL(file)} alt={name} />
                                        ): (
                                            profile?.pictures && <img src={img + profile?.pictures} alt={name} />
                                        )}
                                    </div>
                                    <div className="name">
                                        <h6>{profile?.name}</h6>
                                        <span>{profile?.position}</span>
                                    </div>
                                    <div className='blog-details cont'>
                                        <div className="cont">
                                            { authDecode && (authDecode?.user?.id === profile.UserId || authDecode?.user?.role >=4) ? (
                                                 // eslint-disable-next-line
                                                <ul> 
                                                    <li><Link to='#edit'><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/></Link>Edit</li>
                                                    <li><Link to='#delete'><i className="fa fa-trash" aria-hidden="true" onClick={() => deleteProfile(profile.id)} /></Link>Delete</li>
                                                </ul>
                                            ) : (<></>)}
                                        </div>
                                    </div>
                                </div> { /* <!-- teachers left --> */}
                            </div>
                            <div className="col-lg-8">
                                <div className="teachers-right mt-50">
                                    <ul className="nav nav-justified" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <Link className="active" id="dashboard-tab" data-toggle="tab" href="#dashboard" role="tab" aria-controls="dashboard" aria-selected="true">Bio</Link>
                                        </li>
                                    </ul> { /* <!-- nav --> */}
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                                            <div className="dashboard-cont pt-20" dangerouslySetInnerHTML={{ __html: bio}} style={{whiteSpace: "pre-line"}} />
                                        </div>
                                    </div> { /* <!-- tab content --> */}
                                </div> { /* <!-- teachers right --> */}
                            </div>
                        </div> // <!-- row --> 
                    }
                </div> { /* <!-- container --> */}
            </section>
        </>
    )
}

export default Profile
