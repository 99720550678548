import RightSideBar from "../../Components/RightSideBar";
import NewStoryForm from "./NewStoryForm";
import NewsPageBanner from "./NewsPageBanner";

function AddStory({img}) {
  return (
    <>
      <NewsPageBanner PT='Post News Story' img={img}/>
      <div className="pt-90 pb-120 gray-bg">
        <div className="container">
          <div className="row">
            <NewStoryForm/>
            <RightSideBar img={img}/>
          </div>
        </div>
      </div>
    </>

  );
}

export default AddStory