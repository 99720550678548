import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import {Link} from 'react-router-dom';

function EventsPageBanner({PT, img}) {
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

    return (
        <section id="page-banner" className="pt-105 pb-80 bg_cover" data-overlay="8" style={{backgroundImage: `url(${img}page-banner-3.jpg)`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-banner-cont">
                            <h2>Events</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    { PT ? 
                                        <>
                                            <li className="breadcrumb-item active"><Link to='/events'>Events</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{PT}</li>
                                        </>
                                    :
                                        <li className="breadcrumb-item active" aria-current="page">Events</li>
                                    }
                                </ol>
                                <br />
                                {(authDecode && authDecode?.user?.role >=3) && 
                                    <ol className="breadcrumb"><li className="breadcrumb-item active"><Link to="/events/add"><i className="fa fa-calendar"></i></Link> Add Event</li></ol>
                                }    
                            </nav>
                        </div>  { /* <!-- page banner cont --> */}
                    </div>
                </div> { /* <!-- row --> */}
            </div> { /* <!-- container --> */}
        </section>
    )
}

export default EventsPageBanner