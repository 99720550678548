import { Link, useNavigate, useLocation} from "react-router-dom";
import useLogout from '../Hooks/useLogout';
import useAuth from '../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';

function Navbar({img}) {
    const { auth } = useAuth();
    const location = useLocation();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const from = location.state?.from?.pathname || `/`;
    const logOut = useLogout();
    let navigate = useNavigate();

    const signOut = async () => {  
        await logOut(); 
        navigate(from, { replace: true });
    }

    let activeLocation = location?.pathname.split('/').pop();
    
    let activeNav = '';
    
    if (activeLocation === 'about') {
        activeNav = 'about';
    } else if(activeLocation === 'principal') {
        activeNav = 'about';
    } else if(activeLocation === 'director') {
        activeNav = 'about';
    } else if(activeLocation === 'governors') {
        activeNav = 'about';
    } else if(activeLocation === 'management') {
        activeNav = 'about';
    } else if(activeLocation === 'teachers') {
        activeNav = 'about';
    } else if(activeLocation === 'academic'){
        activeNav = 'learning';
    } else if(activeLocation === 'arts'){
        activeNav = 'learning';
    } else if(activeLocation === 'sport'){
        activeNav = 'learning';
    } else if(activeLocation === 'leadership'){
        activeNav = 'learning';
    } else if(activeLocation === 'admissions'){
        activeNav = 'admissions';
    } else if(activeLocation === 'apply'){
        activeNav = 'admissions';
    } else if(activeLocation === 'fees'){
        activeNav = 'admissions';
    } else if(activeLocation === 'news'){
        activeNav = 'news';
    } else if(activeLocation === 'events'){
        activeNav = 'news';
    } else if(activeLocation === 'blog'){
        activeNav = 'news';
    } else if(activeLocation === 'gallery'){
        activeNav = 'news';
    } else if(activeLocation === 'contact'){
        activeNav = 'contact';
    } else if(activeLocation === 'vacancies'){
        activeNav = 'contact';
    }
    
    return (
        <>
            <header id="header-part">
                <div className="header-top d-none d-lg-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="header-contact text-lg-left text-center">
                                    <ul>
                                        <li>
                                            <img src={`${img}all-icon/map.png`} alt="icon" />
                                            <span>Plot 6A Channel, Yusuf Lule Road, Kampala</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="header-opening-time text-lg-right text-center">
                                    <div className='footer-about'>
                                        <ul>
                                            <li><Link to="https://facebook.com/kampalajunioracademyschool"><i className="fa fa-facebook-f"></i></Link></li>
                                            <li><Link to="https://x.com/kjalearners"><i className="fa fa-twitter"></i></Link></li>
                                            <li><Link to="https://youtube.com/@Kampalajunioracademy"><i className="fa fa-youtube-play"></i></Link></li>
                                            <li><Link to="https://instagram.com/kampalajunioracademyofficial/"><i className="fa fa-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> {/* <!-- row --> */} 
                    </div> {/*  <!-- container --> */} 
                </div> {/*  <!-- header top --> */} 
                
                <div className="header-logo-support pt-10 pb-10">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={`${img}logo.png`} alt="Logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8">
                                <div className="support-button float-right d-none d-md-block">
                                    <div className="support float-left">
                                        <div className="icon">
                                            <img src={`${img}all-icon/support.png`} alt="icon" />
                                        </div>
                                        <div className="cont">
                                            <p>Need Help? Call us</p>
                                            <span>+256 392 700 370</span>
                                        </div>
                                    </div>
                                    <div className="button float-left">
                                        <Link to="/apply" className="main-btn">Enroll Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div> { /*  <!-- row --> */}
                    </div> { /*  <!-- container --> */}
                </div> { /*  <!-- header logo support --> */}
                
                <div className="navigation">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-sm-6 col-6">
                                <nav className="navbar navbar-expand-lg">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>

                                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        <ul className="navbar-nav mr-auto">
                                            {/* <li className="nav-item">
                                                <Link className={location.pathname === '/' ? 'active' : ''} to="/">Home</Link>
                                            </li> */}
                                            <li className="nav-item">
                                                <Link className={activeNav === 'about' ? 'active' : ''} to="#about">About</Link>
                                                <ul className="sub-menu">
                                                    <li><Link className={activeLocation === 'about' ? 'active' : ''} to="/about">About Us</Link></li>                                                   
                                                    <li><Link className={activeLocation === 'director' ? 'active' : ''} to="/director">From the Director</Link></li>
                                                    {/* 
                                                        <li><Link className={activeLocation === 'principal' ? 'active' : ''} to="/principal">From the Principal</Link></li>
                                                        <li><Link className={activeLocation === 'management' ? 'active' : ''} to="/management">Management</Link></li>
                                                        <li><Link className={activeLocation === 'teachers' ? 'active' : ''} to="/teachers">Teachers</Link></li>
                                                        <li><Link className={activeLocation === 'kitante' ? 'active' : ''} to="#kitante">Kitante Campus</Link></li>
                                                        <li><Link className={activeLocation === 'najja' ? 'active' : ''} to="#najja">Najja Campus</Link></li>
                                                        <li><Link className={activeLocation === 'bunga' ? 'active' : ''} to="#bunga">Bunga Campus</Link></li>
                                                        <li><Link className={activeLocation === 'ntinda' ? 'active' : ''} to="#ntinda">Ntinda Campus</Link></li>
                                                        <li><Link className={activeLocation === 'makindye' ? 'active' : ''} to="#makindye">Makindye Campus</Link></li> 
                                                    */}
                                                </ul>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={activeNav === 'learning' ? 'active' : ''} to="#">Learning</Link>
                                                <ul className="sub-menu">
                                                    <li><Link className={activeLocation === 'academic' ? 'active' : ''} to="/academic">Academic</Link></li>
                                                    <li><Link className={activeLocation === 'arts' ? 'active' : ''} to="/arts">Arts & Culture</Link></li>
                                                    <li><Link className={activeLocation === 'sport' ? 'active' : ''} to="/sport">Sport</Link></li>
                                                    <li><Link className={activeLocation === 'leadership' ? 'active' : ''} to="/leadership">Leadership & Service</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={activeNav === 'admissions' ? 'active' : ''} to="#">Admissions</Link>
                                                <ul className="sub-menu">
                                                    <li><Link className={activeLocation === 'admissions' ? 'active' : ''} to="/admissions">Admission Process</Link></li>
                                                    <li><Link className={activeLocation === 'apply' ? 'active' : ''} to="/apply">Apply Online</Link></li>
                                                    <li><Link className={activeLocation === 'fees' ? 'active' : ''} to="/fees">Fees & Payments</Link></li>
                                                </ul>
                                            </li>
                                           <li className="nav-item">
                                                <Link className={activeNav === 'news' ? 'active' : ''} to="#news">News & Events</Link>
                                                <ul className="sub-menu">
                                                    <li><Link className={activeLocation === 'news' ? 'active' : ''} to="/news">News</Link></li>
                                                    <li><Link className={activeLocation === 'events' ? 'active' : ''} to="/events">Events & Fixtures</Link></li>
                                                    <li><Link className={activeLocation === 'blog' ? 'active' : ''} to="/blog">Blog</Link></li>
                                                    <li><Link className={activeLocation === 'gallery' ? 'active' : ''} to="/gallery">Gallery</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={activeNav === 'contact' ? 'active' : ''} to="#">Contact</Link>
                                                <ul className="sub-menu">
                                                    <li><Link className={activeLocation === 'contact' ? 'active' : ''} to="/contact">Contact Us</Link></li>
                                                    <li><Link className={activeLocation === 'apply' ? 'active' : ''} to="/visit">Visit Us</Link></li>
                                                    <li><Link className={activeLocation === 'vacancies' ? 'active' : ''} to="/vacancies">Staff Vacancies</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="https://www.kampalajunioracademy.ac.ug/onlineschool">Online School</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav> { /* <!-- nav --> */}
                            </div> { /*  <!-- row --> */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                <div className="right-icon text-right">
                                <ul>
                                    {authDecode ? 
                                        <>
                                            <li><Link to="#logout"><i className="fa fa-sign-out" onClick={signOut}></i></Link></li>
                                            <li><Link to="/user"><i className="fa fa-user"></i></Link></li>
                                            {authDecode?.user?.role >=4 && 
                                                <li><Link to="/admin"><i className="fa fa-cogs"></i></Link></li>                                        
                                            }
                                        </>
                                    : 
                                        <>
                                            <li><Link to="/login"><i className="fa fa-sign-in"></i></Link></li>                                       
                                        </>
                                    }

                                        <li><Link to="#search" id="search"><i className="fa fa-search"></i></Link></li>
                                    </ul>
                                </div> { /*  <!-- right icon --> */}
                            </div>
                        </div> { /*  <!-- container --> */}

                    </div>
                </div> 
            </header>
        </>
    );
}

export default Navbar;