import { Route, Routes} from 'react-router-dom';
import Profiles from '../Modules/Profiles';
import PageNotFound from './PageNotFound'
import Page from './Page';

function Pages({img} ) {  
    return (
        <Routes>
            <Route exact path='/about/*' element={<Page id={'about'} img={img}/>} />
            <Route exact path='/principal/*' element={<Page id={'principal'} img={img}/>} />
            <Route exact path='/director/*' element={<Page id={'director'} img={img}/>} />
            <Route exact path='/academic/*' element={<Page id={'academic'} img={img}/>} />
            <Route exact path='/arts/*' element={<Page id={'arts'} img={img}/>} />
            <Route exact path='/sport/*' element={<Page id={'sport'}  img={img}/>}/>
            <Route exact path='/leadership/*' element={<Page id={'leadership'} img={img}/>} />
            <Route exact path='/admissions/*' element={<Page id={'admissions'} img={img}/>} />
            <Route exact path='/fees/*' element={<Page id={'fees'} img={img}/>} />
            <Route exact path='/vacancies/*' element={<Page id={'vacancies'} img={img}/>} />
            <Route exact path='/support/*' element={<Page id={'support'} img={img}/>} />
            <Route exact path='/visit/*' element={<Page id={'visit'} img={img}/>} />
            <Route exact path='/governors/*' element={<Profiles id={10} img={img}/>} />
            <Route exact path='/management/*' element={<Profiles id={20} img={img}/>} />
            <Route exact path='/teachers/*' element={<Profiles id={30} img={img}/>} />       
            <Route exact path='/*' element={<PageNotFound img={img}/>} />
      </Routes>
    )
}

export default Pages