import { Link, useNavigate, useLocation} from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import useAuth from '../Hooks/useAuth';
import axios from '../Assets/axios';

const LOGIN_URL = '/users/login';

//let userId = '';

const Login = ({img}) => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const userRef = useRef();
    const errRef = useRef();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL, JSON.stringify({ user, pwd }), {headers: { 'Content-Type': 'application/json' }, withCredentials: true });
            const accessToken = response?.data?.accessToken;
            //const userDecode = jwtDecode(accessToken);
            //const from = location.state?.from?.pathname || `/user/view/${userDecode.user.id}`;
            const from = location.state?.from?.pathname || `/`;
            setAuth({ accessToken });          
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
        <>
            <section id="page-banner" className="pt-105 pb-80 bg_cover" data-overlay="8" style={{backgroundImage: `url(${img}page-banner-6.jpg)`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-banner-cont">
                                <h2>Sign In</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Sign In</li>
                                    </ol>
                                </nav>
                            </div>  { /* { /*<!-- page banner cont --> */} 
                        </div>
                    </div> { /* { /*<!-- row --> */} 
                </div> { /* { /*<!-- container --> */}
            </section>

            <section id="teachers-part" className="pt-90 pb-120 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className='contact-from mt-30'>
                                <div className="section-title">
                                    <h5>login</h5>
                                    <h2>Sign In</h2>
                                </div> { /* <!-- section title --> */ }
                                <div className='main-form pt-45'>
                                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="section-title singel-form form-group">
                                            <label htmlFor="username"><h5>Email</h5></label>
                                            <input type="text" id="username" ref={userRef} autoComplete="off" onChange={(e) => setUser(e.target.value)} value={user} required />
                                        </div>
                                        <div className="section-title singel-form form-group">
                                            <label htmlFor="password"><h5>Password</h5></label>
                                            <input type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} required />
                                        </div>
                                        <br />
                                        <button className="main-btn">Sign In</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="happy-student mt-30">
                                <div className="happy-title">
                                    <h3>Promotion</h3>
                                </div>
                                <div className="student-slied">
                                    <div className="singel-student">
                                        <img src={`${img}teachers/teacher-2/quote.png`} alt="Quote" />
                                        <p>Aliquetn sollicitudirem quibibendum auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit amet</p>
                                        <h6>Mark Anthem</h6>
                                        <span>Bsc, Engineering</span>
                                    </div> { /*<!-- singel student --> */}
                                </div> { /*<!-- student slied --> */}
                                <div className="student-image">
                                    <img src={`${img}teachers/teacher-2/happy.png`} alt='' />
                                </div>
                            </div> { /*<!-- happy student --> */}
                        </div>
                    </div> { /*<!-- row --> */}
                </div> { /*<!-- container --> */}
            </section>
        </>
    )
}

export default Login