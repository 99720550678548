import {Link} from 'react-router-dom';

function Hero({img}) {
  return (
    <>
        <section id="slider-part" className="slider-active">
            <div className="single-slider bg_cover pt-150" style={{backgroundImage: `url(${img}slider/s-2.jpg)`}} data-overlay="4">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-9">
                            <div className="slider-cont">
                                <h1 data-animation="bounceInLeft" data-delay="1s">Choose the right school for your child</h1>
                                <p data-animation="fadeInUp" data-delay="1.3s">Kampala Junior Academy is a holistic powerhouse dedicated to pushing the boundaries of education to impact and influence future leaders.</p>
                                <ul>
                                    <li><Link data-animation="fadeInUp" data-delay="1.6s" className="main-btn" to="/admissions">Read More</Link></li>
                                    <li><Link data-animation="fadeInUp" data-delay="1.9s" className="main-btn main-btn-2" to="/apply">Get Started</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div> { /* <!-- row --> */}
                </div> { /* <!-- container --> */}
            </div> { /* <!-- single slider --> */}
        
        </section>

        <section id="category-part">
            <div className="container">
                <div className="category pt-30 pb-60">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="category-text pt-20">
                                <h2> ....for future leaders</h2>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-1 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-8 offset-2">
                            <div className="row category-slied mt-40">
                                <div className="col-lg-3 mb-4">
                                    <Link to="/academic">
                                        <span className="singel-category text-center color-1">
                                            <span className="icon">
                                                <img src={`${img}all-icon/ctg-3.png`} alt="Icon" />
                                            </span>
                                            <span className="cont">
                                            <span>Academics</span>
                                            </span>
                                        </span> 
                                    </Link>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <Link to="/arts">
                                    <span className="singel-category text-center color-2">
                                        <span className="icon">
                                        <img src={`${img}all-icon/arts.png`} alt="Icon" />
                                        </span>
                                        <span className="cont">
                                        <span>Performing Arts</span>
                                        </span>
                                    </span>
                                    </Link>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <Link to="/sport">
                                    <span className="singel-category text-center color-3">
                                        <span className="icon">
                                        <img src={`${img}all-icon/soccer-3.png`} alt="Icon" />
                                        </span>
                                        <span className="cont">
                                        <span>Sports</span>
                                        </span>
                                    </span> 
                                    </Link>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <Link to="/leadership">
                                    <span className="singel-category text-center color-1">
                                        <span className="icon">
                                            <img src={`${img}all-icon/leadership.png`} alt="Icon" />
                                        </span>
                                        <span className="cont">
                                        <span>Leadership</span>
                                        </span>
                                    </span> 
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div> 
            </div>
        </section>
    </> 
  )
}

export default Hero