import { Link } from 'react-router-dom';

function Footer({img}) {
    return (  
        <footer id="footer-part">
            <div className="footer-top pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about mt-40">
                                <div className="logo">
                                    <Link to="/"><img src={`${img}logo-2.png`} alt="Logo" style={{ height: '120px'}} /></Link>
                                </div>
                                <p> </p>
                                <ul className="mt-20">
                                    <li><Link to="https://facebook.com/kampalajunioracademyschool"><i className="fa fa-facebook-f"></i></Link></li>
                                    <li><Link to="https://x.com/kjalearners"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to="https://youtube.com/@Kampalajunioracademy"><i className="fa fa-youtube-play"></i></Link></li>
                                    <li><Link to="https://instagram.com/kampalajunioracademyofficial/"><i className="fa fa-instagram"></i></Link></li>
                                </ul>
                            </div> { /* <!-- footer about --> */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer-link mt-40">
                                <div className="footer-title pb-25">
                                    <h6>Sitemap</h6>
                                </div>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-angle-right"></i>Home</Link></li>
                                    <li><Link to="/about"><i className="fa fa-angle-right"></i>About</Link></li>
                                    <li><Link to="/news"><i className="fa fa-angle-right"></i>News</Link></li>
                                    <li><Link to="/events"><i className="fa fa-angle-right"></i>Events</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/gallery"><i className="fa fa-angle-right"></i>Gallery</Link></li>
                                    <li><Link to="/blog"><i className="fa fa-angle-right"></i>Blogs</Link></li>
                                    <li><Link to="/teachers"><i className="fa fa-angle-right"></i>Teachers</Link></li>
                                    <li><Link to="/contact"><i className="fa fa-angle-right"></i>Contact</Link></li>
                                </ul>
                            </div> { /* <!-- footer link --> */}
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="footer-link support mt-40">
                                <div className="footer-title pb-25">
                                    <h6>Support</h6>
                                </div>
                                <ul>
                                    <li><Link to="/admissions"><i className="fa fa-angle-right"></i>Admissions</Link></li>
                                    <li><Link to="/apply"><i className="fa fa-angle-right"></i>Apply Online</Link></li>
                                    <li><Link to="/contact"><i className="fa fa-angle-right"></i>Inquiries</Link></li>
                                    <li><Link to="/faqs"><i className="fa fa-angle-right"></i>FAQs</Link></li>
                                </ul>
                            </div> { /* <!-- support --> */}
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-address mt-40">
                                <div className="footer-title pb-25">
                                    <h6>Contact Us</h6>
                                </div>
                                <ul>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-home"></i>
                                        </div>
                                        <div className="cont">
                                            <p>Plot 6A Channel, Yusuf Lule Road, Kampala</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-phone"></i>
                                        </div>
                                        <div className="cont">
                                            <p>+256 392 700 370</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-envelope-o"></i>
                                        </div>
                                        <div className="cont">
                                            <p>info@kja.ug</p>
                                        </div>
                                    </li>
                                </ul>
                            </div> { /* <!-- footer address --> */}
                        </div>
                    </div> { /* <!-- row --> */}
                </div> { /* <!-- container --> */}
            </div> { /* <!-- footer top --> */}
            
            <div className="footer-copyright">
                <div className="container">
                    <div className="text-md-right text-center">
                        <Link target="_blank" rel="noreferrer" to="https://www.templateshub.net">Templates Hub</Link>
                    </div>
                </div> { /* <!-- container --> */}
            </div> { /* <!-- footer copyright --> */}
        </footer>
    )
}

export default Footer