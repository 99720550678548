import {Link} from 'react-router-dom';
import EventsList from './EventsList';
import PagesIntro from './PagesIntro';

function About({img}) {
  const todayDate = new Date();
  let applyNow = new Date().getFullYear();

  if(todayDate.getMonth()>8){
    applyNow = new Date().getFullYear() + 1;
  }

  return (
    <>
      <section id="about-part" className="pt-65">
        <div className="container">
          <div className="row">
            <PagesIntro/>
            <EventsList/>
          </div> 
        </div>
        <div className="about-bg">
          <img src={`${img}about/IMG_7827.jpg`} alt="About" />
        </div>
      </section>

      <section id="apply-aprt" className="pb-120">
        <div className="container">
          <div className="apply">
            <div className="row no-gutters">
              <div className="col-lg-6">
                <div className="apply-cont apply-color-1">
                  <h3>Apply for {applyNow} intake</h3>
                  <p>While applications are encouraged throughout the year, the primary window for new entrants typically spans from October to January. Registration is currently ongoing and all are encouraged to apply.</p>
                  <Link to="/apply" className="main-btn">Apply Now</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="apply-cont apply-color-2">
                  <h3>Apply for scholarship</h3>
                  <p>Financial support through various scholarships and bursaries is available for learners who meet specific criteria. Read more on the eligibility criteria and submit your application if your child qualifies.</p>
                  <Link to="/" className="main-btn">Apply Now</Link>
                </div> 
              </div> 
            </div>
          </div> 
        </div> 
      </section>      
    </>
  )
}

export default About